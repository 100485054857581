.header {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #09cab3;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.header h1 {
    font: normal normal normal 20px/36px SF Pro Display;
    color: #434343;
}

.header .machines h3 {
    font: normal normal normal 20px/36px SF Pro Display;
    color: #434343;
}

.header .machines .names {
    font: normal normal bold 20px/36px SF Pro Display;
}

.form {
    width: 60%;
    margin: 0 auto;
}

.form h3 {
    font: normal normal normal 20px/36px SF Pro Display;
}

.form input {
    background-color: #ffffff;
}

.form hr {
    width: 100%;
    background-color: #B5B5B5;
    opacity: 1;
    margin: 30px 0 22px 8px;
}

.form .buttonContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.form .buttonContainer button {
    background: #09CAB3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #10101033;
    border-radius: 5px;
    font: normal normal bold 14px/16px SF Pro Display;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 19px 60px;
}