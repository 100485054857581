.OrderBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px !important;
}

.OrderBox .Checkbox {
    padding: 0;
}

.OrderBox .Id {
    font: normal normal normal 18px/21px SF Pro Display;
    color: #434343;
}

.OrderBox .Product {
    margin: 0;
    padding-left: 3px;
    font: normal normal normal 18px/28px SF Pro Display;
    color: #434343;
}

.OrderBox .Total {
    font: normal normal normal 26px/31px SF Pro Display;
    color: #09CAB3;
}

.OrderBox .StatusContainer {
    display: flex;
    flex-direction: column;
}

.StatusContainer .Status {
    font: normal normal normal 14px/16px SF Pro Display;
    color: #FC9B00;
}

.StatusContainer .Log {
    padding-top: 5px;
    font: normal normal bold 12px/14px SF Pro Display;
    color: #B5B5B5;
}

.OrderBox .Button {
    padding: 10px 40px;
    background: #09CAB3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #10101033;
    border: none;
    border-radius: 5px;
    font: normal normal bold 14px/16px SF Pro Display;
    color: #FFFFFF;
}

.StatusLabel {
    font: normal normal normal 14px/30px SF Pro Display;
    letter-spacing: 0px;
    color: #B4B4B4;
    font-size: 16px;
    margin-right: 10px;
}

.StatusFilter {
    margin: 0 8px;
    padding: 6px 20px;
    box-shadow: 0px 3px 10px #10101033;
    border: 1px solid #B2B2B2;
    border-radius: 5px;
    font: normal normal normal 14px/30px SF Pro Display;
    color: #B4B4B4;
    cursor: pointer;
}

.StatusFilter:hover,
.StatusFilter.Active {
    border: 1px solid #09CAB3;
    font: normal normal bold 14px/30px SF Pro Display;
    color: #09CAB3;
}

.OrderCheckbox {
    margin: 0 9px !important;
}

.OrderDetailsBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 20px !important;
}

.OrderDetailsBox .Data,
.OrderDetailsBox .Positions {
    border-bottom: 2px solid #B5B5B5;
}

.OrderDetailsBox .Data th,
.OrderDetailsBox .Positions th {
    font: normal normal bold 14px/16px SF Pro Display;
    color: #B9B9B9;
}

.OrderDetailsBox .Data td,
.OrderDetailsBox .Positions td {
    vertical-align: top;
    font: normal normal normal 18px/21px SF Pro Display;
    color: #434343;
}

.OrderDetailsBox .Data td {
    padding-top: 15px;
    padding-bottom: 20px;
}

.OrderDetailsBox .Data td small {
    font: normal normal normal 14px/20px SF Pro Display;
    color: #B5B5B5;
}

.OrderDetailsBox .Positions td {
    padding: 2px;
}

.OrderDetailsBox .Positions tr {
    height: auto;
}

.StatusTimeline {
    display: flex;
    flex-direction: row;
    position: relative;
}

.StatusTimeline .Back {
    position: absolute;
    right: 0;
    bottom: 0;
    border: none;
    background: none;
    font: normal normal bold 16px/19px SF Pro Display;
    color: #09CAB3;
}

.StatusTimeline .StatusPoint {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    padding: 5px;
    width: 12%;
}

.StatusPoint.Active .Timeline .Dot {
    background-color: transparent;
}

.StatusPoint.Active .StatusName,
.StatusPoint.Active .StatusLog {
    font: normal normal bold 14px/16px SF Pro Display;
    color: #09CAB3;
}

.StatusPoint.Active .Timeline hr,
.StatusPoint.InActive .Timeline hr {
    border-top: 1px dashed #D1D1D1;
}

.StatusPoint.InActive .Timeline .Dot {
    background-color: #D1D1D1;
    border-color: #D1D1D1;
}

.StatusPoint.InActive .StatusName {
    font: normal normal normal 14px/16px SF Pro Display;
    color: #D1D1D1;
}

.StatusPoint .Timeline {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    position: relative;
}

.StatusPoint .Timeline .Dot {
    display: block;
    width: 9px;
    height: 9px;
    background: #09CAB3 0% 0% no-repeat padding-box;
    border: 1px solid #09CAB3;
    border-radius: 50%;
}

.StatusPoint .Timeline .Line {
    width: 90%;
    padding: 0 0 0 10px;
}

.StatusPoint .Timeline hr {
    margin: 0;
    height: 1px;
    opacity: 1;
    background: #09CAB3 0% 0% no-repeat padding-box;
}

.StatusPoint .StatusName {
    margin-top: 10px;
    font: normal normal normal 14px/16px SF Pro Display;
    color: #434343;
}

.StatusPoint .StatusLog {
    font: normal normal normal 12px/14px SF Pro Display;
    color: #B5B5B5;
}

.StatusPoint .ButtonSet {
    margin-top: 10px;
    padding: 12px 40px;
    background: #09CAB3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #10101033;
    border-radius: 5px;
    border: none;
    font: normal normal bold 14px/16px SF Pro Display;
    color: #FFFFFF;
}

.StatusPoint .ButtonSet.Currently {
    background-color: transparent;
    box-shadow: none;
    padding: 12px 0;
    font: normal normal bold 14px/16px SF Pro Display;
    color: #B5B5B5;
}

.PaginationContainer {
    display: flex;
    justify-content: space-between;

    padding: 20px 0;
}

.PaginationContainer button {
    padding: 10px 40px;
    background: #09CAB3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #10101033;
    border: none;
    border-radius: 5px;
    font: normal normal bold 14px/16px SF Pro Display;
    color: #FFFFFF;
}

.PaginationContainer button:hover {
    background: #09cab3a1 0% 0% no-repeat padding-box;
}

.FlexEnd {
    justify-content: flex-end;
}