a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.main-navbar .navbar {
  margin-top: 20px;
  box-shadow: none;
  border: none !important;
}

.main-navbar .collapse {
  display: block !important;
}

.main-navbar .nav-link {
  font: normal normal normal 20px/36px SF Pro Display !important;
  letter-spacing: 0px;
  color: #B5B5B5 !important;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
}

.main-navbar .nav-link:hover,
.main-navbar .nav-link.active {
  font-weight: bold !important;
  color: #09CAB3 !important;
}

.main-navbar .nav-link .active-underline {
  opacity: 0;
  height: 3px;
  width: 50%;
  background: #09CAB3 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.main-navbar .nav-link.active .active-underline {
  opacity: 1;
}

.main-navbar .logout-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #09CAB3;
}

.LogoSmall {
  background: url('../images/logo_small.png') no-repeat;
  background-size: 100%;
  width: 165px;
  height: 60px;
  float: right;
  margin-right: 10px;
  display: flex;
  align-self: center;
}


.main-navbar .user-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.user-bar a {
  font: normal normal bold 14px/16px SF Pro Display;
  color: #434343;
  text-decoration: none;
  padding: 12px 0;
}

.user-bar a:hover,
.user-bar a.active {
  color: #09CAB3;
  border-bottom: 2px solid #09CAB3;
  padding: 12px 0 10px 0;
}